import VueRouter from 'vue-router'
 
const router = new VueRouter({
  routes: [
    {
      path: '',
      redirect: "/home"
    },
    {
      path: '/home',
      component: () => import('@/pages/home'),
    }, 
    {
      path: '/activity',
      component: () => import('@/pages/activity/activity')
    }, 
    // {
    //   path: '/vuexTest',
    //   component: () => import('@/views/vuexTest')
    // }, {
    //   path: '/minixsTest',
    //   component: () => import('@/views/minixsTest')
    // }, {
    //   path: '/injectTest',
    //   component: () => import('@/views/injectTest')
    // }
  ],
})

export default router;